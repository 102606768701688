import { LightenColor } from '@energyweb/origin-ui-theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
  },
  button: {
    borderRadius: 0,
    justifyContent: 'flex-start',
    fontWeight: 600,
    letterSpacing: '0.1rem',
    padding: '10px 20px',
    width: '100%',
    color: theme.palette?.text.primary,
    // '&:hover': {
    //   backgroundColor: LightenColor(
    //     theme.palette?.background.paper,
    //     5,
    //     theme.palette?.mode
    //   ),
    // },
    // High specificity hover for light mode
    '&.MuiButton-root.MuiButtonBase-root:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#3f2367' : LightenColor(
        theme.palette.background.paper,
        5,
        theme.palette.mode
      ),
    },
    
    // Additional hover state for buttons in general
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#3f2367' : LightenColor(
        theme.palette.background.paper,
        5,
        theme.palette.mode
      ),
    },
  },
}));
