// import { Chip } from '@mui/material';
// import React, { Dispatch, FC } from 'react';
// import type { FileUploadAction, UploadedFile } from '../FileUpload.types';
// import { useUploadChipEffects } from './UploadChip.effects';

// export interface UploadChipProps {
//   file: File;
//   index: number;
//   uploadedFile: UploadedFile;
//   dispatch: Dispatch<FileUploadAction>;
// }

// export const UploadChip: FC<UploadChipProps> = ({
//   file,
//   index,
//   uploadedFile,
//   dispatch,
// }) => {
//   const {
//     lightenBgColor,
//     bgColorLight,
//     chipLabel,
//     chipIcon,
//     chipDeleteIcon,
//     deleteHandler,
//   } = useUploadChipEffects({ file, index, uploadedFile, dispatch });

//   return (
//     <Chip
//       variant="outlined"
//       color="primary"
//       label={chipLabel}
//       icon={chipIcon}
//       deleteIcon={chipDeleteIcon}
//       clickable={uploadedFile.cancelled}
//       onDelete={deleteHandler}
//       style={{
//         margin: 5,
//         background: `-webkit-linear-gradient(left, ${
//           uploadedFile.cancelled ? lightenBgColor : bgColorLight
//         } ${
//           uploadedFile.cancelled ? '100' : uploadedFile.uploadProgress
//         }%, rgba(255, 255, 255, 0) 0%)`,
//         cursor: uploadedFile.cancelled ? 'pointer' : 'default',
//       }}
//       key={index}
//     />
//   );
// };
// import { Chip } from '@mui/material';
// import React, { Dispatch, FC } from 'react';
// import type { FileUploadAction, CustomFile, UploadedFile } from '../FileUpload.types';
// import { useUploadChipEffects } from './UploadChip.effects';

// export interface UploadChipProps {
//   file: CustomFile;
//   index: number;
//   uploadedFile?: UploadedFile;
//   dispatch: Dispatch<FileUploadAction>;
//   isInitial?: boolean;
// }

// export const UploadChip: FC<UploadChipProps> = ({
//   file,
//   index,
//   uploadedFile,
//   dispatch,
//   isInitial,
// }) => {
//   const {
//     lightenBgColor,
//     bgColorLight,
//     chipLabel,
//     chipIcon,
//     chipDeleteIcon,
//     deleteHandler,
//   } = useUploadChipEffects({ file, index, uploadedFile, dispatch });

//   console.log('UploadChip Props:', { file, index, uploadedFile });

//   return (
//     <Chip
//       variant="outlined"
//       color="primary"
//       label={chipLabel}
//       icon={chipIcon}
//       deleteIcon={chipDeleteIcon}
//       onDelete={deleteHandler}
//       style={{
//         margin: 5,
//         background: `-webkit-linear-gradient(left, ${
//           uploadedFile?.cancelled ? lightenBgColor : bgColorLight
//         } ${
//           uploadedFile?.cancelled ? '100' : uploadedFile?.uploadProgress || 100
//         }%, rgba(255, 255, 255, 0) 0%)`,
//         cursor: uploadedFile?.cancelled ? 'pointer' : 'default',
//       }}
//       key={index}
//     />
//   );
// };
import { Chip } from '@mui/material';
import React, { Dispatch, FC } from 'react';
import type {
  FileUploadAction,
  CustomFile,
  UploadedFile,
} from '../FileUpload.types';
import { useUploadChipEffectsDevice } from './UploadChip.effectsDevice';
import { useFileContext } from '@energyweb/origin-ui-device-view'; // Import the hook

export interface UploadChipDeviceProps {
  file: CustomFile | File;
  index: number;
  uploadedFile?: UploadedFile;
  dispatch: Dispatch<FileUploadAction>;
  isInitial?: boolean;
}

export const UploadChipDevice: FC<UploadChipDeviceProps> = ({
  file,
  index,
  uploadedFile,
  dispatch,
  isInitial,
}) => {
  const { removedInitialFiles, setRemovedInitialFiles } = useFileContext(); // Use the hook

  const {
    lightenBgColor,
    bgColorLight,
    chipLabel,
    chipIcon,
    chipDeleteIcon,
    deleteHandler,
  } = useUploadChipEffectsDevice({ file, index, uploadedFile, dispatch, isInitial });

  console.log('UploadChip render:', { file, index, uploadedFile, isInitial });

  return (
    <Chip
      variant="outlined"
      color="primary"
      label={chipLabel}
      icon={chipIcon}
      deleteIcon={chipDeleteIcon}
      onDelete={() => {
        console.log('Delete icon clicked:', { file, index, isInitial });
        deleteHandler();
        if (isInitial && 'filename' in file) {
          // Check if 'filename' exists in file
          setRemovedInitialFiles((prev) => [...prev, file as CustomFile]);
          console.log('Removed Initial Files:', [
            ...removedInitialFiles,
            file as CustomFile,
          ]);
        }
      }}
      style={{
        margin: 5,
        background: `-webkit-linear-gradient(left, ${
          uploadedFile?.cancelled ? lightenBgColor : bgColorLight
        } ${
          uploadedFile?.cancelled ? '100' : uploadedFile?.uploadProgress || 100
        }%, rgba(255, 255, 255, 0) 0%)`,
        cursor: uploadedFile?.cancelled ? 'pointer' : 'default',
      }}
      key={index}
    />
  );
};
