// import { Box, Typography, TypographyProps } from '@mui/material';
// import React, { FC } from 'react';
// import { UploadChip } from './UploadChip';
// import { useFileUploadEffects } from './FileUpload.effects';
// import { useStyles } from './FileUpload.styles';
// import {
//   ApiUploadFunction,
//   FileUploadOnChangeFunction,
// } from './FileUpload.types';

// export interface FileUploadProps {
//   dropzoneText: string;
//   apiUploadFunction: ApiUploadFunction;
//   onChange: FileUploadOnChangeFunction;
//   heading?: string;
//   headingProps?: TypographyProps;
//   wrapperProps?: React.DetailedHTMLProps<
//     React.HTMLAttributes<HTMLElement>,
//     HTMLElement
//   > & { ['data-cy']?: string };
//   dropzoneClassName?: string;
// }

// export const FileUpload: FC<FileUploadProps> = ({
//   dropzoneText,
//   apiUploadFunction,
//   onChange,
//   heading,
//   headingProps,
//   wrapperProps,
//   dropzoneClassName,
// }) => {
//   const { getRootProps, getInputProps, files, state, dispatch } =
//     useFileUploadEffects({ apiUploadFunction, onChange });
//   const classes = useStyles();
//   const dropzoneClasses = `${classes.dropzone} ${dropzoneClassName}`;

//   return (
//     <Box>
//       {heading && <Typography {...headingProps}>{heading}</Typography>}
//       <section className={classes.wrapper} {...wrapperProps}>
//         <div
//           {...getRootProps({ className: dropzoneClasses })}
//           style={{
//             ...(getRootProps().style as React.CSSProperties),
//           }}
//         >
//           <input
//             {...getInputProps()}
//             style={{
//               ...(getInputProps().style as React.CSSProperties),
//             }}
//           />
//           <p className={classes.dropzoneText}>{dropzoneText}</p>
//         </div>
//         <aside className={classes.chipsContainer}>
//           {files.map((file, index) => {
//             const uploadedFile = state[index];

//             if (!uploadedFile || uploadedFile.removed) {
//               return null;
//             }
//             return (
//               <UploadChip
//                 key={file.name + index}
//                 file={file}
//                 index={index}
//                 uploadedFile={uploadedFile}
//                 dispatch={dispatch}
//               />
//             );
//           })}
//         </aside>
//       </section>
//     </Box>
//   );
// };
// import { Box, Typography, TypographyProps } from '@mui/material';
// import React, { FC, useEffect } from 'react';
// import { UploadChip } from './UploadChip';
// import { useFileUploadEffects } from './FileUpload.effects';
// import { useStyles } from './FileUpload.styles';
// import {
//   ApiUploadFunction,
//   FileUploadOnChangeFunction,
//   CustomFile,
// } from './FileUpload.types';

// export interface FileUploadProps {
//   dropzoneText: string;
//   apiUploadFunction: ApiUploadFunction;
//   onChange: FileUploadOnChangeFunction;
//   heading?: string;
//   headingProps?: TypographyProps;
//   wrapperProps?: React.DetailedHTMLProps<
//     React.HTMLAttributes<HTMLElement>,
//     HTMLElement
//   > & { ['data-cy']?: string };
//   dropzoneClassName?: string;
//   initialFiles?: CustomFile[];
// }

// export const FileUpload: FC<FileUploadProps> = ({
//   dropzoneText,
//   apiUploadFunction,
//   onChange,
//   heading,
//   headingProps,
//   wrapperProps,
//   dropzoneClassName,
//   initialFiles = [],
// }) => {
//   const { getRootProps, getInputProps, files, state, dispatch, setInitialFiles } =
//     useFileUploadEffects({ apiUploadFunction, onChange });
//   const classes = useStyles();
//   const dropzoneClasses = `${classes.dropzone} ${dropzoneClassName}`;

//   // useEffect(() => {
//   //   setInitialFiles(initialFiles);
//   // }, [initialFiles, setInitialFiles]);

//   useEffect(() => {
//     setInitialFiles(initialFiles);
//     console.log('Setting Initial Files:', initialFiles);
//   }, [initialFiles, setInitialFiles]);

//   useEffect(() => {
//     console.log('Files state:', files);
//     console.log('File Upload state:', state);
//   }, [files, state]);

//   return (
//     <Box>
//       {heading && <Typography {...headingProps}>{heading}</Typography>}
//       <section className={classes.wrapper} {...wrapperProps}>
//         <div
//           {...getRootProps({ className: dropzoneClasses })}
//           style={{
//             ...(getRootProps().style as React.CSSProperties),
//           }}
//         >
//           <input
//             {...getInputProps()}
//             style={{
//               ...(getInputProps().style as React.CSSProperties),
//             }}
//           />
//           <p className={classes.dropzoneText}>{dropzoneText}</p>
//         </div>
//         <aside className={classes.chipsContainer}>
//           {initialFiles.map((file, index) => (
//             <UploadChip
//               key={file.filename + index}
//               file={file}
//               index={index}
//               dispatch={dispatch}
//               isInitial
//             />
//           ))}
//           {files.map((file, index) => {
//             const uploadedFile = state[index];

//             if (!uploadedFile || uploadedFile.removed) {
//               return null;
//             }
//             return (
//               <UploadChip
//                 key={file.name + index}
//                 file={file}
//                 index={index}
//                 uploadedFile={uploadedFile}
//                 dispatch={dispatch}
//               />
//             );
//           })}
//         </aside>
//       </section>
//     </Box>
//   );
// };

// import { Box, Typography, TypographyProps } from '@mui/material';
// import React, { FC, useEffect, useState } from 'react';
// import { UploadChip } from './UploadChip';
// import { useFileUploadEffects } from './FileUpload.effects';
// import { useStyles } from './FileUpload.styles';
// import {
//   ApiUploadFunction,
//   FileUploadOnChangeFunction,
//   CustomFile,
// } from './FileUpload.types';

// export interface FileUploadProps {
//   dropzoneText: string;
//   apiUploadFunction: ApiUploadFunction;
//   onChange: FileUploadOnChangeFunction;
//   heading?: string;
//   headingProps?: TypographyProps;
//   wrapperProps?: React.DetailedHTMLProps<
//     React.HTMLAttributes<HTMLElement>,
//     HTMLElement
//   > & { ['data-cy']?: string };
//   dropzoneClassName?: string;
//   initialFiles?: CustomFile[];
// }

// export const FileUpload: FC<FileUploadProps> = ({
//   dropzoneText,
//   apiUploadFunction,
//   onChange,
//   heading,
//   headingProps,
//   wrapperProps,
//   dropzoneClassName,
//   initialFiles = [],
// }) => {
//   const { getRootProps, getInputProps, files, state, dispatch, setInitialFiles } =
//     useFileUploadEffects({ apiUploadFunction, onChange });
//   const classes = useStyles();
//   const dropzoneClasses = `${classes.dropzone} ${dropzoneClassName}`;
//   const [removedInitialFiles, setRemovedInitialFiles] = useState<Set<number>>(new Set());

//   useEffect(() => {
//     setInitialFiles(initialFiles);
//   }, [initialFiles, setInitialFiles]);

//   const handleRemoveInitialFile = (index: number) => {
//     setRemovedInitialFiles((prev) => new Set(prev).add(index));
//     dispatch({
//       type: 'removeInitialFile',
//       payload: {
//         id: index,
//       },
//     });
//   };

//   return (
//     <Box>
//       {heading && <Typography {...headingProps}>{heading}</Typography>}
//       <section className={classes.wrapper} {...wrapperProps}>
//         <div
//           {...getRootProps({ className: dropzoneClasses })}
//           style={{
//             ...(getRootProps().style as React.CSSProperties),
//           }}
//         >
//           <input
//             {...getInputProps()}
//             style={{
//               ...(getInputProps().style as React.CSSProperties),
//             }}
//           />
//           <p className={classes.dropzoneText}>{dropzoneText}</p>
//         </div>
//         <aside className={classes.chipsContainer}>
//           {initialFiles.map((file, index) => {
//             if (removedInitialFiles.has(index)) {
//               return null;
//             }
//             return (
//               <UploadChip
//                 key={file.filename + index}
//                 file={file}
//                 index={index}
//                 dispatch={dispatch}
//                 isInitial
//                 onRemoveInitial={() => handleRemoveInitialFile(index)}
//               />
//             );
//           })}
//           {files.map((file, index) => {
//             const fileIndex = index + initialFiles.length;
//             const uploadedFile = state[fileIndex];

//             if (!uploadedFile || uploadedFile.removed) {
//               return null;
//             }
//             return (
//               <UploadChip
//                 key={file.name + index}
//                 file={file}
//                 index={fileIndex}
//                 uploadedFile={uploadedFile}
//                 dispatch={dispatch}
//               />
//             );
//           })}
//         </aside>
//       </section>
//     </Box>
//   );
// };
// import { Box, Typography, TypographyProps } from '@mui/material';
// import React, { FC, useEffect } from 'react';
// import { UploadChip } from './UploadChip';
// import { useFileUploadDeviceEffects } from './FileUploadDevice.effects';
// import { useStyles } from './FileUpload.styles';
// import { ApiUploadFunction, CustomFile } from './FileUpload.types';
// import { useRemovedFiles } from '@energyweb/origin-ui-device-view'; // Import the hook

// export interface FileUploadDeviceProps {
//   dropzoneText: string;
//   apiUploadFunction: ApiUploadFunction;
//   onChange: (files: CustomFile[], removedInitialFiles?: string[]) => void; // Make the second argument optional
//   heading?: string;
//   headingProps?: TypographyProps;
//   wrapperProps?: React.DetailedHTMLProps<
//     React.HTMLAttributes<HTMLElement>,
//     HTMLElement
//   > & { ['data-cy']?: string };
//   dropzoneClassName?: string;
//   initialFiles?: CustomFile[];
// }

// export const FileUploadDevice: FC<FileUploadDeviceProps> = ({
//   dropzoneText,
//   apiUploadFunction,
//   onChange,
//   heading,
//   headingProps,
//   wrapperProps,
//   dropzoneClassName,
//   initialFiles = [],
// }) => {
//   const { getRootProps, getInputProps, files, state, dispatch, setInitialFiles } =
//     useFileUploadDeviceEffects({ apiUploadFunction, onChange, initialFiles }); // Pass initialFiles to the hook
//   const { removedInitialFiles, setRemovedInitialFiles } = useRemovedFiles(); // Use the hook
//   const classes = useStyles();
//   const dropzoneClasses = `${classes.dropzone} ${dropzoneClassName}`;

//   useEffect(() => {
//     setInitialFiles(initialFiles);
//   }, [initialFiles, setInitialFiles]);

//   useEffect(() => {
//     // Notify parent component of the removed initial files
//     const customFiles = files.map((file) => ({
//       filename: file.name,
//       contentType: file.type,
//     }));
//     console.log('Files:', customFiles);
//     console.log('Removed Initial Files:', removedInitialFiles.map(index => initialFiles[Number(index)]?.filename || ''));
//     onChange(customFiles, removedInitialFiles.map(index => initialFiles[Number(index)]?.filename || ''));
//   }, [files, removedInitialFiles, onChange, initialFiles]);

//   const handleRemoveInitialFile = (index: number) => {
//     setRemovedInitialFiles((prev) => [...prev, index.toString()]); // Store indices as strings
//     dispatch({
//       type: 'removeInitialFile',
//       payload: {
//         id: index,
//       },
//     });
//     console.log('Removed Initial File Index:', index);
//   };

//   return (
//     <Box>
//       {heading && <Typography {...headingProps}>{heading}</Typography>}
//       <section className={classes.wrapper} {...wrapperProps}>
//         <div
//           {...getRootProps({ className: dropzoneClasses })}
//           style={{
//             ...(getRootProps().style as React.CSSProperties),
//           }}
//         >
//           <input
//             {...getInputProps()}
//             style={{
//               ...(getInputProps().style as React.CSSProperties),
//             }}
//           />
//           <p className={classes.dropzoneText}>{dropzoneText}</p>
//         </div>
//         <aside className={classes.chipsContainer}>
//           {initialFiles.map((file, index) => {
//             if (removedInitialFiles.includes(index.toString())) { // Check if the index is in the removedInitialFiles
//               return null;
//             }
//             return (
//               <UploadChip
//                 key={file.filename + index}
//                 file={file}
//                 index={index}
//                 dispatch={dispatch}
//                 isInitial
//                 onRemoveInitial={() => handleRemoveInitialFile(index)}
//               />
//             );
//           })}
//           {files.map((file, index) => {
//             const fileIndex = index + initialFiles.length;
//             const uploadedFile = state[fileIndex];

//             if (!uploadedFile || uploadedFile.removed) {
//               return null;
//             }
//             return (
//               <UploadChip
//                 key={file.name + index}
//                 file={file}
//                 index={fileIndex}
//                 uploadedFile={uploadedFile}
//                 dispatch={dispatch}
//               />
//             );
//           })}
//         </aside>
//       </section>
//     </Box>
//   );
// };
// import { Box, Typography, TypographyProps } from '@mui/material';
// import React, { FC, useEffect } from 'react';
// import { UploadChip } from './UploadChip';
// import { useFileUploadDeviceEffects } from './FileUploadDevice.effects';
// import { useStyles } from './FileUpload.styles';
// import { ApiUploadFunction, CustomFile } from './FileUpload.types';
// import { useRemovedFiles } from '@energyweb/origin-ui-device-view'; // Import the hook

// export interface FileUploadDeviceProps {
//   dropzoneText: string;
//   apiUploadFunction: ApiUploadFunction;
//   onChange: (files: CustomFile[]) => void; // Make the second argument optional
//   heading?: string;
//   headingProps?: TypographyProps;
//   wrapperProps?: React.DetailedHTMLProps<
//     React.HTMLAttributes<HTMLElement>,
//     HTMLElement
//   > & { ['data-cy']?: string };
//   dropzoneClassName?: string;
//   initialFiles?: CustomFile[];
// }

// export const FileUploadDevice: FC<FileUploadDeviceProps> = ({
//   dropzoneText,
//   apiUploadFunction,
//   onChange,
//   heading,
//   headingProps,
//   wrapperProps,
//   dropzoneClassName,
//   initialFiles = [],
// }) => {
//   const { getRootProps, getInputProps, files, state, dispatch, setInitialFiles } =
//     useFileUploadDeviceEffects({ apiUploadFunction, onChange, initialFiles }); // Pass initialFiles to the hook
//   const { removedInitialFiles, setRemovedInitialFiles } = useRemovedFiles(); // Use the hook
//   const classes = useStyles();
//   const dropzoneClasses = `${classes.dropzone} ${dropzoneClassName}`;

//   useEffect(() => {
//     setInitialFiles(initialFiles);
//   }, [initialFiles, setInitialFiles]);

//   useEffect(() => {
//     // Notify parent component of the removed initial files
//     const customFiles = files.map((file) => ({
//       filename: file.name,
//       contentType: file.type,
//     }));
//     console.log('Files:', customFiles);
//     console.log('Removed Initial Files:', removedInitialFiles.map(index => initialFiles[Number(index)]?.filename || ''));
//     onChange(customFiles);
//   }, [files, removedInitialFiles, onChange, initialFiles]);

//   // const handleRemoveInitialFile = (index: number) => {
//   //   setRemovedInitialFiles((prev) => [...prev, index.toString()]); // Store indices as strings
//   //   dispatch({
//   //     type: 'removeInitialFile',
//   //     payload: {
//   //       id: index,
//   //     },
//   //   });
//   //   console.log('Removed Initial File Index:', index);
//   // };

//   return (
//     <Box>
//       {heading && <Typography {...headingProps}>{heading}</Typography>}
//       <section className={classes.wrapper} {...wrapperProps}>
//         <div
//           {...getRootProps({ className: dropzoneClasses })}
//           style={{
//             ...(getRootProps().style as React.CSSProperties),
//           }}
//         >
//           <input
//             {...getInputProps()}
//             style={{
//               ...(getInputProps().style as React.CSSProperties),
//             }}
//           />
//           <p className={classes.dropzoneText}>{dropzoneText}</p>
//         </div>
//         <aside className={classes.chipsContainer}>
//           {initialFiles.map((file, index) => {
//             if (removedInitialFiles.includes(index.toString())) { // Check if the index is in the removedInitialFiles
//               return null;
//             }
//             return (
//               <UploadChip
//                 key={file.filename + index}
//                 file={file}
//                 index={index}
//                 dispatch={dispatch}
//                 isInitial
//                 // onRemoveInitial={() => handleRemoveInitialFile(index)}
//               />
//             );
//           })}
//           {files.map((file, index) => {
//             const fileIndex = index + initialFiles.length;
//             const uploadedFile = state[fileIndex];

//             if (!uploadedFile || uploadedFile.removed) {
//               return null;
//             }
//             return (
//               <UploadChip
//                 key={file.name + index}
//                 file={file}
//                 index={fileIndex}
//                 uploadedFile={uploadedFile}
//                 dispatch={dispatch}
//               />
//             );
//           })}
//         </aside>
//       </section>
//     </Box>
//   );
// };

// import { Box, Typography, TypographyProps } from '@mui/material';
// import React, { FC, useEffect } from 'react';
// import { UploadChip } from './UploadChip';
// import { useFileUploadDeviceEffects } from './FileUploadDevice.effects';
// import { useStyles } from './FileUpload.styles';
// import { ApiUploadFunction, FileUploadOnChangeFunction, CustomFile } from './FileUpload.types';
// import { useFileContext } from '@energyweb/origin-ui-device-view'; // Import the hook

// export interface FileUploadDeviceProps {
//   dropzoneText: string;
//   apiUploadFunction: ApiUploadFunction;
//   onChange: FileUploadOnChangeFunction;
//   heading?: string;
//   headingProps?: TypographyProps;
//   wrapperProps?: React.DetailedHTMLProps<
//     React.HTMLAttributes<HTMLElement>,
//     HTMLElement
//   > & { ['data-cy']?: string };
//   dropzoneClassName?: string;
//   initialFiles?: CustomFile[];
// }

// export const FileUploadDevice: FC<FileUploadDeviceProps> = ({
//   dropzoneText,
//   apiUploadFunction,
//   onChange,
//   heading,
//   headingProps,
//   wrapperProps,
//   dropzoneClassName,
//   initialFiles = [],
// }) => {
//   // const { getRootProps, getInputProps, files, state, dispatch, setInitialFiles } =
//   //   useFileUploadDeviceEffects({ apiUploadFunction, onChange, initialFiles }); // Pass initialFiles to the hook
//   const { getRootProps, getInputProps, files, state, dispatch } =
//     useFileUploadDeviceEffects({ apiUploadFunction, onChange, initialFiles });
//   const { removedInitialFiles } = useFileContext(); // Use the hook
//   const classes = useStyles();
//   const dropzoneClasses = `${classes.dropzone} ${dropzoneClassName}`;

//   // useEffect(() => {
//   //   setInitialFiles(initialFiles);
//   // }, [initialFiles, setInitialFiles]);

//   return (
//     <Box>
//       {heading && <Typography {...headingProps}>{heading}</Typography>}
//       <section className={classes.wrapper} {...wrapperProps}>
//         <div
//           {...getRootProps({ className: dropzoneClasses })}
//           style={{
//             ...(getRootProps().style as React.CSSProperties),
//           }}
//         >
//           <input
//             {...getInputProps()}
//             style={{
//               ...(getInputProps().style as React.CSSProperties),
//             }}
//           />
//           <p className={classes.dropzoneText}>{dropzoneText}</p>
//         </div>
//         <aside className={classes.chipsContainer}>
//           {initialFiles.map((file, index) => {
//             if (removedInitialFiles.includes(file.filename)) { // Check if the filename is in the removedInitialFiles
//               return null;
//             }
//             return (
//               <UploadChip
//                 key={file.filename + index}
//                 file={file}
//                 index={index}
//                 dispatch={dispatch}
//                 isInitial
//               />
//             );
//           })}
//           {files.map((file, index) => {
//             const fileIndex = index + initialFiles.length;
//             const uploadedFile = state[fileIndex];

//             if (!uploadedFile || uploadedFile.removed) {
//               console.log('File removed:', file.name);
//               console.log('uploadedFile:', uploadedFile);
//               console.log('state:', state);
//               return null;
//             }
//             return (
//               <UploadChip
//                 key={file.name + index}
//                 file={file}
//                 index={fileIndex}
//                 uploadedFile={uploadedFile}
//                 dispatch={dispatch}
//               />
//             );
//           })}
//         </aside>
//       </section>
//     </Box>
//   );
// };
import React, { FC, useEffect } from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';
import { useStyles } from './FileUpload.styles';
import {
  ApiUploadFunction,
  FileUploadOnChangeFunction,
  CustomFile,
} from './FileUpload.types';
import { useFileContext } from '@energyweb/origin-ui-device-view'; // Import the hook
import { UploadChipDevice } from './UploadChip';
import { useFileUploadDeviceEffects } from './FileUploadDevice.effects'; // Assuming this exists

export interface FileUploadDeviceProps {
  dropzoneText: string;
  apiUploadFunction: ApiUploadFunction;
  onChange: FileUploadOnChangeFunction;
  heading?: string;
  headingProps?: TypographyProps;
  wrapperProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > & { ['data-cy']?: string };
  dropzoneClassName?: string;
  initialFiles?: CustomFile[];
}

export const FileUploadDevice: FC<FileUploadDeviceProps> = ({
  dropzoneText,
  apiUploadFunction,
  onChange,
  heading,
  headingProps,
  wrapperProps,
  dropzoneClassName,
  initialFiles = [],
}) => {
  const { getRootProps, getInputProps, files, state, dispatch } =
    useFileUploadDeviceEffects({ apiUploadFunction, onChange, initialFiles });
  const { removedInitialFiles, uploadedFiles, setUploadedFiles } =
    useFileContext(); // Use the hook
  const classes = useStyles();
  const dropzoneClasses = `${classes.dropzone} ${dropzoneClassName}`;

  // Effect to update uploaded files in context whenever a file is successfully uploaded
  // useEffect(() => {
  //   files.forEach((file, index) => {
  //     const uploadedFile = state[index];
  //     if (uploadedFile && !uploadedFile.cancelled && uploadedFile.uploadProgress === 100) {
  //       setUploadedFiles((prev) => {
  //         if (!prev.includes(uploadedFile.uploadedName)) {
  //           return [...prev, uploadedFile.uploadedName];
  //         }
  //         return prev;
  //       });
  //     }
  //   });
  // }, [files, state, setUploadedFiles]);

  return (
    <Box>
      {heading && <Typography {...headingProps}>{heading}</Typography>}
      <section className={classes.wrapper} {...wrapperProps}>
        <div
          {...getRootProps({ className: dropzoneClasses })}
          style={{
            ...(getRootProps().style as React.CSSProperties),
          }}
        >
          <input
            {...getInputProps()}
            style={{
              ...(getInputProps().style as React.CSSProperties),
            }}
          />
          <p className={classes.dropzoneText}>{dropzoneText}</p>
        </div>
        <aside className={classes.chipsContainer}>
          {initialFiles.map((file, index) => {
            if (
              removedInitialFiles.some(
                (removedFile) => removedFile.filename === file.filename
              )
            ) {
              return null;
            }
            return (
              <UploadChipDevice
                key={file.filename + index}
                file={file}
                index={index}
                dispatch={dispatch}
                isInitial
              />
            );
          })}
          {files.map((file, index) => {
            const fileIndex = index + initialFiles.length;
            const uploadedFile = state[fileIndex];

            if (!uploadedFile || uploadedFile.removed) {
              console.log('File removed:', file.name);
              console.log('uploadedFile:', uploadedFile);
              console.log('state:', state);
              return null;
            }

            // Remove canceled files from context
            // if (uploadedFile.cancelled && uploadedFile.uploadedName) {
            //   setUploadedFiles((prev) => prev.filter((id) => id !== uploadedFile.uploadedName));
            // }

            return (
              <UploadChipDevice
                key={file.name + index}
                file={file}
                index={fileIndex}
                uploadedFile={uploadedFile}
                dispatch={dispatch}
              />
            );
          })}
        </aside>
      </section>
    </Box>
  );
};
