// import { LightenColor } from '@energyweb/origin-ui-theme';
// import { Cancel, Delete, Replay } from '@mui/icons-material';
// import { useTheme } from '@mui/material';
// import React from 'react';
// import { UploadChipProps } from './UploadChip';

// export const useUploadChipEffects = ({
//   file,
//   uploadedFile,
//   index,
//   dispatch,
// }: UploadChipProps) => {
//   const theme = useTheme();
//   const originBgColor = theme.palette.background.paper;
//   const originTextColor = theme.palette.primary.contrastText;

//   const lightenBgColor = LightenColor(originBgColor, 3, theme.palette.mode);
//   const bgColorLight = LightenColor(originTextColor, -10, theme.palette.mode);

//   const chipLabel = `${
//     file.name.length > 20 ? file.name.slice(0, 20) + '...' : file.name
//   }${uploadedFile.cancelled ? ' (Cancelled, click to retry)' : ''}`;

//   const chipIcon = uploadedFile.cancelled ? <Replay /> : null;

//   const chipDeleteIcon =
//     uploadedFile.uploadedName || uploadedFile.cancelled ? (
//       <Delete color="error" />
//     ) : (
//       <Cancel color="error" />
//     );

//   const deleteHandler = () => {
//     if (uploadedFile.uploadedName || uploadedFile.cancelled) {
//       dispatch({
//         type: 'deleteFile',
//         payload: {
//           id: index,
//         },
//       });
//     } else {
//       uploadedFile.cancelToken.cancel();

//       dispatch({
//         type: 'cancelFileUpload',
//         payload: {
//           id: index,
//         },
//       });
//     }
//   };

//   return {
//     lightenBgColor,
//     bgColorLight,
//     chipLabel,
//     chipIcon,
//     chipDeleteIcon,
//     deleteHandler,
//   };
// };
// import { LightenColor } from '@energyweb/origin-ui-theme';
// import { Cancel, Delete, Replay, Image } from '@mui/icons-material'; // Import Image icon
// import { useTheme } from '@mui/material';
// import React from 'react';
// import { UploadChipProps } from './UploadChip';

// export const useUploadChipEffects = ({
//   file,
//   uploadedFile,
//   index,
//   dispatch,
// }: UploadChipProps) => {
//   const theme = useTheme();
//   const originBgColor = theme.palette.background.paper;
//   const originTextColor = theme.palette.primary.contrastText;

//   const lightenBgColor = LightenColor(originBgColor, 3, theme.palette.mode);
//   const bgColorLight = LightenColor(originTextColor, -10, theme.palette.mode);

//   const chipLabel = `${file.filename}`;

//   const chipIcon = file.contentType.startsWith('image/')
//     ? <Image /> // Use generic Image icon for image files
//     : <div>{file.contentType.split('/')[1].toUpperCase()}</div>; // Use file type label for non-image files

//   const chipDeleteIcon =
//     uploadedFile?.uploadedName || uploadedFile?.cancelled ? (
//       <Delete color="error" />
//     ) : (
//       <Cancel color="error" />
//     );

//   const deleteHandler = () => {
//     if (uploadedFile?.uploadedName || uploadedFile?.cancelled) {
//       dispatch({
//         type: 'deleteFile',
//         payload: {
//           id: index,
//         },
//       });
//     } else if (uploadedFile) {
//       uploadedFile.cancelToken?.cancel();

//       dispatch({
//         type: 'cancelFileUpload',
//         payload: {
//           id: index,
//         },
//       });
//     }
//   };

//   console.log('useUploadChipEffects:', { file, uploadedFile, chipLabel });
  
//   return {
//     lightenBgColor,
//     bgColorLight,
//     chipLabel,
//     chipIcon,
//     chipDeleteIcon,
//     deleteHandler,
//   };
// };

// import { LightenColor } from '@energyweb/origin-ui-theme';
// import { Cancel, Delete, Replay, Image } from '@mui/icons-material';
// import { useTheme } from '@mui/material';
// import React from 'react';
// import { UploadChipProps } from './UploadChip';
// import { CustomFile } from '../FileUpload.types';

// function isCustomFile(file: File | CustomFile): file is CustomFile {
//   return (file as CustomFile).contentType !== undefined;
// }

// export const useUploadChipEffects = ({
//   file,
//   uploadedFile,
//   index,
//   dispatch,
// }: UploadChipProps) => {
//   const theme = useTheme();
//   const originBgColor = theme.palette.background.paper;
//   const originTextColor = theme.palette.primary.contrastText;

//   const lightenBgColor = LightenColor(originBgColor, 3, theme.palette.mode);
//   const bgColorLight = LightenColor(originTextColor, -10, theme.palette.mode);

//   const chipLabel = 'filename' in file ? file.filename : file.name;

//   const chipIcon = isCustomFile(file)
//     ? file.contentType.startsWith('image/')
//       ? <Image />
//       : <div>{file.contentType.split('/')[1].toUpperCase()}</div>
//     : file.type.startsWith('image/')
//     ? <Image />
//     : <div>{file.type.split('/')[1].toUpperCase()}</div>;

//   const chipDeleteIcon =
//     uploadedFile?.uploadedName || uploadedFile?.cancelled ? (
//       <Delete color="error" />
//     ) : (
//       <Cancel color="error" />
//     );

//   const deleteHandler = () => {
//     if (uploadedFile?.uploadedName || uploadedFile?.cancelled) {
//       dispatch({
//         type: 'deleteFile',
//         payload: {
//           id: index,
//         },
//       });
//     } else if (uploadedFile) {
//       uploadedFile.cancelToken?.cancel();

//       dispatch({
//         type: 'cancelFileUpload',
//         payload: {
//           id: index,
//         },
//       });
//     }
//   };

//   console.log('useUploadChipEffects:', { file, uploadedFile, chipLabel });
  
//   return {
//     lightenBgColor,
//     bgColorLight,
//     chipLabel,
//     chipIcon,
//     chipDeleteIcon,
//     deleteHandler,
//   };
// };
import { LightenColor } from '@energyweb/origin-ui-theme';
import { Cancel, Delete, Image, PictureAsPdf, Description } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import React from 'react';
import { UploadChipDeviceProps } from './UploadChipDevice';
import { CustomFile } from '../FileUpload.types';

function isCustomFile(file: File | CustomFile): file is CustomFile {
  return (file as CustomFile).contentType !== undefined;
}

export const useUploadChipEffectsDevice = ({
  file,
  uploadedFile,
  index,
  dispatch,
  isInitial,
}: UploadChipDeviceProps) => {
  const theme = useTheme();
  const originBgColor = theme.palette.background.paper;
  const originTextColor = theme.palette.primary.contrastText;

  const lightenBgColor = LightenColor(originBgColor, 3, theme.palette.mode);
  const bgColorLight = LightenColor(originTextColor, -10, theme.palette.mode);

  const chipLabel = isCustomFile(file) ? file.filename : file.name;

  const getIcon = (mimeType: string) => {
    switch (mimeType) {
      case 'image/jpeg':
      case 'image/png':
        return <Image />;
      case 'application/pdf':
        return <PictureAsPdf />;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <Description />;
      default:
        return <div>{mimeType.split('/')[1].toUpperCase()}</div>;
    }
  };

  const chipIcon = isCustomFile(file)
    ? getIcon(file.contentType)
    : getIcon(file.type);

  const chipDeleteIcon = isInitial ? <Delete color="error" /> : uploadedFile?.uploadedName || uploadedFile?.cancelled ? <Delete color="error" /> : <Cancel color="error" />;

  const deleteHandler = () => {
    console.log('Delete handler called:', { isInitial, uploadedFile });
    if (isInitial) {
      console.log('Initial file remove triggered');
    } else if (uploadedFile?.uploadedName || uploadedFile?.cancelled) {
      console.log('Uploaded or cancelled file remove triggered');
      dispatch({
        type: 'deleteFile',
        payload: {
          id: index,
        },
      });
    } else if (uploadedFile) {
      console.log('In-progress file cancel triggered');
      uploadedFile.cancelToken?.cancel();

      dispatch({
        type: 'cancelFileUpload',
        payload: {
          id: index,
        },
      });
    }
  };

  return {
    lightenBgColor,
    bgColorLight,
    chipLabel,
    chipIcon,
    chipDeleteIcon,
    deleteHandler,
  };
};



