// import { LightenColor } from '@energyweb/origin-ui-theme';
// import { makeStyles } from '@mui/styles';

// export const useStyles = makeStyles((theme) => ({
//   selected: {
//     backgroundColor: LightenColor(
//       theme.palette.background.paper,
//       3,
//       theme.palette.mode 
//     ),
//   },
//   listItem: {
//     padding: 0,
//   },
//   button: {
//     justifyContent: 'flex-start',
//     textTransform: 'none',
//     padding: '10px 20px',
//     width: '100%',
//     margin: '0',
//     borderRadius: 0,
//     color: LightenColor(theme.palette.text.secondary, 5, theme.palette.mode),
//     '&:hover': {
//       backgroundColor: LightenColor(
//         theme.palette.background.paper,
//         5,
//         theme.palette.mode
//       ),
//     },
//   },
// }));
import { LightenColor } from '@energyweb/origin-ui-theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  // selected: {
  //   backgroundColor: LightenColor(
  //     theme.palette.background.paper,
  //     3,
  //     theme.palette.mode 
  //   ),
  // },
  selected: {
    backgroundColor: theme.palette.mode === 'light' ? '#200448' : LightenColor(
      theme.palette.background.paper,
      3,
      theme.palette.mode
    ),
    color: '#FFFFFF', // Ensure the text is white for readability
  },
  listItem: {
    padding: 0,
  },
  button: {
    justifyContent: 'flex-start',
    textTransform: 'none',
    padding: '10px 20px',
    width: '100%',
    margin: '0',
    borderRadius: 0,
    color: LightenColor(theme.palette.text.secondary, 5, theme.palette.mode),

    // High specificity hover for light mode
    '&.MuiButton-root.MuiButtonBase-root:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#3f2367' : LightenColor(
        theme.palette.background.paper,
        5,
        theme.palette.mode
      ),
    },
    
    // Additional hover state for buttons in general
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#3f2367' : LightenColor(
        theme.palette.background.paper,
        5,
        theme.palette.mode
      ),
    },
  },
}));
