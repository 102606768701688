import { withPrimaryColor } from './primaryColoringHoc';
import { ReactComponent as WindRegular } from './images/device-type-regular/icon-wind-regular.svg';
import { ReactComponent as SolarRegular } from './images/device-type-regular/icon-solar-regular.svg';
import { ReactComponent as GaseousRegular } from './images/device-type-regular/icon-gaseous-regular.svg';
import { ReactComponent as HydroRegular } from './images/device-type-regular/icon-hydro-regular.svg';
import { ReactComponent as LiquidRegular } from './images/device-type-regular/icon-liquid-regular.svg';
import { ReactComponent as MarineRegular } from './images/device-type-regular/icon-marine-regular.svg';
import { ReactComponent as SolidRegular } from './images/device-type-regular/icon-solid-regular.svg';
import { ReactComponent as ThermalRegular } from './images/device-type-regular/icon-thermal-regular.svg';

export const WindSelected = withPrimaryColor(WindRegular);
export const SolarSelected = withPrimaryColor(SolarRegular);
export const GaseousSelected = withPrimaryColor(GaseousRegular);
export const HydroSelected = withPrimaryColor(HydroRegular);
export const LiquidSelected = withPrimaryColor(LiquidRegular);
export const MarineSelected = withPrimaryColor(MarineRegular);
export const SolidSelected = withPrimaryColor(SolidRegular);
export const ThermalSelected = withPrimaryColor(ThermalRegular);

export { ReactComponent as EnergyWebLogo } from './images/EW-Origin-WhiteText.svg';
export { default as NetZeroWhiteLogo } from './images/net-0-modern-white-transparency.png';
export { default as ITrackAccreditedLogo } from './images/I-TRACK-Accredited-Platform-Operator-White.png';
export { default as IRECProductCodeLogo } from './images/I-REC-Product Code Logo-Electricity-Horizontal.png';
export { ReactComponent as OrganizationAdded } from './images/icon-org-added.svg';
export { ReactComponent as CoordinatesLogo } from './images/coordinates-logo.svg';
export { default as NetZeroBackground } from './images/net-0_Cover.png';
export { ReactComponent as IRecLogo } from './images/IREC-logo.svg';
export { ReactComponent as IRecLogoDark } from './images/IREC-logo_dark.svg';

export { ReactComponent as WindRegular } from './images/device-type-regular/icon-wind-regular.svg';
export { ReactComponent as SolarRegular } from './images/device-type-regular/icon-solar-regular.svg';
export { ReactComponent as GaseousRegular } from './images/device-type-regular/icon-gaseous-regular.svg';
export { ReactComponent as HydroRegular } from './images/device-type-regular/icon-hydro-regular.svg';
export { ReactComponent as LiquidRegular } from './images/device-type-regular/icon-liquid-regular.svg';
export { ReactComponent as MarineRegular } from './images/device-type-regular/icon-marine-regular.svg';
export { ReactComponent as SolidRegular } from './images/device-type-regular/icon-solid-regular.svg';
export { ReactComponent as ThermalRegular } from './images/device-type-regular/icon-thermal-regular.svg';

export { ReactComponent as ThailandFlag } from './images/flags/thailand_flag.svg';

// Middle Eastern Countries
export { ReactComponent as AEFlag } from './images/flags/4x3/ae.svg'; // United Arab Emirates
export { ReactComponent as SAFlag } from './images/flags/4x3/sa.svg'; // Saudi Arabia
export { ReactComponent as QAFlag } from './images/flags/4x3/qa.svg'; // Qatar
export { ReactComponent as KWFlag } from './images/flags/4x3/kw.svg'; // Kuwait
export { ReactComponent as BHFlag } from './images/flags/4x3/bh.svg'; // Bahrain
export { ReactComponent as OMFlag } from './images/flags/4x3/om.svg'; // Oman
export { ReactComponent as YEFlag } from './images/flags/4x3/ye.svg'; // Yemen
export { ReactComponent as JOFlag } from './images/flags/4x3/jo.svg'; // Jordan
export { ReactComponent as IQFlag } from './images/flags/4x3/iq.svg'; // Iraq
export { ReactComponent as SYFlag } from './images/flags/4x3/sy.svg'; // Syria
export { ReactComponent as LBFlag } from './images/flags/4x3/lb.svg'; // Lebanon
export { ReactComponent as PSFlag } from './images/flags/4x3/ps.svg'; // Palestine
export { ReactComponent as ILFlag } from './images/flags/4x3/il.svg'; // Israel
export { ReactComponent as IRFlag } from './images/flags/4x3/ir.svg'; // Iran
export { ReactComponent as PKFlag } from './images/flags/4x3/pk.svg'; // Pakistan
export { ReactComponent as AFFlag } from './images/flags/4x3/af.svg'; // Afghanistan

// Northern African Countries
export { ReactComponent as DZFlag } from './images/flags/4x3/dz.svg'; // Algeria
export { ReactComponent as EGFlag } from './images/flags/4x3/eg.svg'; // Egypt
export { ReactComponent as LYFlag } from './images/flags/4x3/ly.svg'; // Libya
export { ReactComponent as MAFlag } from './images/flags/4x3/ma.svg'; // Morocco
export { ReactComponent as SDFlag } from './images/flags/4x3/sd.svg'; // Sudan
export { ReactComponent as TNFlag } from './images/flags/4x3/tn.svg'; // Tunisia
